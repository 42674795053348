import s from 'styled-components'
import WallpaperTop from '../../images/Homepage banner/homebanner_v3.jpg'
import WallpaperMain from '../../images/wallpaper-3.jpg'
import React from 'react'

const H1 = s.h1`color:#000;margin: 0 0 5px 0;font-size: 21px;`
const Input = s.input`
    background-color: #717171;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #d2d2d2;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #d2d2d2;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #d2d2d2;
    }
`
const Button = s.button`
    cursor: pointer;
    backface-visibility: hidden;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    background: linear-gradient(180deg,#ef783d 0%,#ce642f 47.08838632142467%,#ce7042 51%,#d25e24 98.48137222768491%);
    border-radius: 5px;
    border: 1px solid #cf7028;
    border-width: 1px 1px 1px 1px;
    padding: 10px 20px 10px 20px;
    box-shadow: inset 0px 1px 0px #ffffff,0px 1px 3px rgba(0,0,0,0.3);
    color: #1b1b1b;
    font-size: 16px;
    font-family: Helvetica Neue;
    font-weight: 900;
    font-style: normal;
    text-shadow: 0px -1px 0px rgba(0,0,0,0.4)
`
// background-color: #ED2024;
// color: #fff;
// border: none;
// border-radius: 6px;
// padding: 10px;
// min-width: 100px;

const Box = s.div`
    padding: 10px 30px;
    text-align:center;
`

const Warpper = s.div`
    margin: 0 auto;
    max-width: 480px;
    position: relative;
    height: 100vh;
`

const ImgMain = s.img`
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
`

const BoxMain = s.div`
  position: absolute;
  top:0;
  left:0;
`

const BoxWithText = s.div`
    padding: 10px 30px;
    text-align:left;
    font-size: 16px;
    font-family: Helvetica Neue;
    font-weight: 900;
    font-style: normal;
`

const ViewHome = props => (
  <Warpper>
    <ImgMain src={WallpaperMain} alt="" />
    <BoxMain>
      <img src={WallpaperTop} alt="" style={{ width: '100%' }} />
      {props.predefineId ? null :
        <BoxWithText>
          รหัสพนักงานที่ชวนคุณ
        <Input type="tel" ref={props.refInputId} placeholder="(ยกตัวอย่างเช่น 1234567)" maxlength="8" />
        </BoxWithText>
      }
      <BoxWithText>
        {props.predefineId ? null : 'กรอกเบอร์โทรศัพท์​ที่ต้องการสมัคร'}
        <Input type="tel" ref={props.refInput} placeholder="(ยกตัวอย่างเช่น 081234567)" maxlength="10"/>
      </BoxWithText>
      <Box>
        <Button onClick={props.onSubmit}>กดยืนยัน</Button>
      </Box>
    </BoxMain>
  </Warpper>
)

export default ViewHome
